















































import ProductApi from "@/apis/product/ProductApi";
import RentApi from "@/apis/stock/RentStockApi";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ProductCategories from "@/components/ui/ProductCategories.vue";
import Products from "@/components/ui/Products.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import { BaseRepo } from "@/models/BaseRepo";
import { RentStock, RentStockStateType } from "@/models/RentStock";
import { ProductCategory } from "@/models/Product";
import BaseView from "@/views/BaseView.vue";
import ProductItems from "@/views/models/admin/ProductItems";
import { Component, Emit, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    ProductCategories,
    Products,
  },
})
export default class CreateRentForm extends BaseView {
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: any) {
    return data;
  }
  @Prop() StockType!: boolean;

  @Prop() categories!: Array<ProductCategory>;

  private rentApi: RentApi = new RentApi();
  private productApi: ProductApi = new ProductApi();
  private product: Array<ProductItems> = [];
  private productCategoryId = 0;
  private rentStockState!: RentStockStateType;

  private params = {
    id: 0,
    org_id: 0,
    product_id: 0,
    serial_number: "",
    state: "ready",
    remark: "",
  } as RentStock;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
    productModelSelect: InstanceType<typeof ValidationSelect>;
  };

  private async save() {
    const result = await this.$refs.observer.validate();
    if (result) {
      this.isConnecting = true;
      try {
        const repo = await this.rentApi.create(this.params);
        const data: any = repo.data as BaseRepo;
        this.submit(data);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isConnecting = false;
      }
    }
  }

  private async onSelectProductCategory(category_id: any) {
    this.$refs.productModelSelect.onChangeValue("");
    this.productCategoryId = category_id;

    try {
      const params = {
        productCategoryId: this.productCategoryId,
      };
      const repo = await this.productApi.getEnabledProducts(params);
      this.product = [...repo.data];
    } catch (e) {
      this.handleError(e);
    }
  }

  private onSelectProduct(select_id: any) {
    this.params.product_id = select_id;
  }

  private onChangeBroken(checked: boolean) {
    this.params.state = checked ? "broken" : "ready";
  }
}
